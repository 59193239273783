<template>
    <div class='account_manage'>
        <!-- 标题 -->
        <div class="vue_title">活动列表</div>
        <div class="account_main">
            <!--  -->
            <div class="main_condition">
                <div class="cond_left">
                    <el-button type="primary" class="btnBgColor_blue_empty" @click="addEditBtn()" size="small" icon="el-icon-plus">新增</el-button>
                    <el-input v-model="title" size="small" @keyup.enter.native="search" @clear='search' :clearable="true" placeholder="请输入标题"></el-input>
                    <el-select v-model="hdType" size="small" @change="search" :clearable="true" placeholder="请选择活动类型">
                        <el-option v-for="item in hdList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <!-- <div class="cond_right">
                    <el-button type="primary" class="btnBgColor_grey" size="small" icon="el-icon-upload2" @click='exportBtn'>导出</el-button>
                </div> -->
            </div>
            <!--  -->
            <div class="main_table">
                <el-table :data="list" v-loading="loading" style="width: 100%" ref="multipleTable" @selection-change="handleSelectionChange" header-cell-class-name="table-header" cell-class-name="cell-class">
                    <template slot="empty">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </template>
                    <!-- <el-table-column type="selection" align="center" width="55"></el-table-column> -->
                    <el-table-column prop="id" label="ID" align="center" width="60"></el-table-column>
                    <el-table-column prop="type" label="活动类型" align="center" width="120">
                        <template slot-scope="scope">
                            <p v-if="scope.row.type==1">会员活动</p>
                            <p v-if="scope.row.type==2">训练营</p>
                            <p v-if="scope.row.type==3">流量大课</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="封面" align="center">
                        <template slot-scope="scope">
                            <el-image style="width:55px; height: 55px" :src="scope.row.cover" :preview-src-list="srcList" fit="cover">
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" label="标题" align="center" width="150" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="describe" label="描述" align="center" width="180">
                        <template slot-scope="scope">
                            <el-tooltip placement="top">
                                <div slot="content" class="scope_tooltip">{{ scope.row.describe }}</div>
                                <div class="scope_txt one-txt-cut">{{ scope.row.describe }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cont" label="简介" align="center" width="200">
                        <template slot-scope="scope">
                            <el-tooltip placement="top">
                                <div slot="content" class="scope_tooltip">{{ scope.row.cont }}</div>
                                <div class="scope_txt one-txt-cut">{{ scope.row.cont }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="price" label="单价" align="center" width="130"></el-table-column>
                    <el-table-column prop="marking_price" label="划线价" align="center" width="130"></el-table-column>
                    <el-table-column prop="people" label="报名人数" align="center" width="130"></el-table-column>
                    <el-table-column label="状态" width="100">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.status" :active-value='1' :inactive-value='2' :key="scope.row.id" @change="changeSwitch($event,scope.row,scope.$index)"></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sort" label="排序" align="center" width="130">
                        <template slot-scope="scope">
                            <div class="scope_sort">
                                <div class="input" v-if="scope.row.show">
                                    <el-input v-model="inputNum" @keyup.enter.native="$event.target.blur()" @blur="inputTopBlur" @input="limitSort" size="small" placeholder="请输入排序"></el-input>
                                </div>
                                <div class="num" v-else>{{ scope.row.sort }}</div>
                                <div class="icon" v-if="!scope.row.show" @click="sortBtn(scope.$index)">
                                    <i class="el-icon-edit"></i>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="添加时间" align="center" width="160"></el-table-column>
                    <el-table-column prop="update_time" label="修改时间" align="center" width="160"></el-table-column>
                    <el-table-column class-name="last_class" label="操作" align="center" fixed="right" width="210">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" v-if="scope.row.is_top==1" @click="toppingClose(scope.row.id)">取消置顶</el-button>
                            <el-button type="text" size="small" v-if="scope.row.is_top==2" @click="toppingOpen(scope.row.id)">置顶</el-button>
                            <el-button type="text" size="small" @click="toCourse(scope.row.id)">课程内容</el-button>
                            <el-button type="text" size="small" @click="addEditBtn(scope.row.id)">编辑</el-button>
                            <el-button type="text" size="small" @click="delBtn(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="page_box" v-if="total" style="padding-bottom: 20px;">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <!-- 删除提示 -->
        <point-out ref='dialogTip' dialogTitle="提示" type="accountDel" @close='closeListBtn' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">删除该活动后将不在当前列表显示，确定要删除吗？</div>
            </div>
        </point-out>
    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//提示
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
export default {
    components: { PointOut, EmptyData, PagingPage },
    props: {},
    data() {
        return {
            title: '',
            hdType: '',
            hdList:[
                {value:1,label:'会员活动'},
                {value:2,label:'训练营'},
                {value:3,label:'流量大课'},
            ],
            // 
            list: [],
            loading: false,
            ids: [],
            multipleSelection: [],
            //
            page: 1,
            limit: 10,
            total: 0,
            // 删除
            del_id: '',
            srcList: [],
            // 
            inputNum:'',
            inputIndex:null,
        }
    },
    computed: {},
    watch: {},
    created() {
        this.search()
    },
    mounted() { },
    methods: {
        search() {
            this.page = 1
            this.list = []
            this.total = 0
            this.getList()
        },
        // 列表
        getList() {
            var that = this
            that.loading = true
            var params = {
                title: that.title,
                type: that.hdType,
                page: that.page,
                limit: that.limit,
            }
            that.$activityApi.activityList(params).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    that.list = res.result?.data
                    that.total = res.result?.total
                    let arr = []
                    that.list.forEach(ele => {
                        ele.cont = this.removeHTMLTag(ele.introduction)
                        ele.show = false
                        arr.push(ele.cover)
                    })
                    this.srcList = arr
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 富文本去掉标签样式
        removeHTMLTag(str) {
            str = str.replace(/<\/?[^>]*>/g, ""); //去除HTML tag
            str = str.replace(/[ | ]*\n/g, "\n"); //去除行尾空白
            str = str.replace(/\n[\s| | ]*\r/g, "\n"); //去除多余空行
            str = str.replace(/&nbsp;/gi, ""); //去掉&nbsp;
            return str;
        },
        // 课程内容
        toCourse(id) {
            this.$router.push({
                name: 'course_catalog',
                query: {
                    id: id
                }
            })
        },
        // 表单选择
        handleSelectionChange(val) {
            this.ids = []
            val.forEach(item => {
                this.ids.push(item.id)
            })
            this.multipleSelection = val
        },
        // 新增/编辑
        addEditBtn(id) {
            if (id) {
                this.$router.push({
                    name: 'activity_add',
                    query: {
                        id: id
                    }
                })
            } else {
                this.$router.push('/activity/add')
            }
        },
        // 修改状态
        changeSwitch(val, info, index) {
            var that = this
            var params = {
                id: info.id,
                status: val,
            }
            that.$activityApi.activityStatus(params).then(res => {
                if (res.code == 1000) {
                    that.$succMsg(res.message)
                    that.list[index].status = val
                } else {
                    that.list[index].status = val == 1 ? 2 : 1
                    that.$errMsg(res.message)
                }
            })
        },
        // 删除
        delBtn(id) {
            this.del_id = id
            this.$refs.dialogTip.openDialogBtn()
        },
        closeListBtn() {
            var that = this
            if (that.del_id !== '') {
                that.$activityApi.activityDel(this.del_id).then(res => {
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.getList()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
        // 置顶
        toppingOpen(vid){
            var that = this
            var params = {
                id:vid,
                is_top:1
            }
            that.$activityApi.activityIsTop(params).then(res => {
                if (res.code == 1000) {
                    that.$succMsg(res.message)
                    that.getList()
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 取消置顶
        toppingClose(vid){
            var that = this
            var params = {
                id:vid,
                is_top:2
            }
            that.$activityApi.activityIsTop(params).then(res => {
                if (res.code == 1000) {
                    that.$succMsg(res.message)
                    that.getList()
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 编辑排序
        sortBtn(index){
            var arr = JSON.parse(JSON.stringify(this.list))
            arr.forEach((ite,idx)=>{
                if(index == idx){
                    this.inputIndex = index
                    ite.show = true
                    this.inputNum = ite.sort
                }else{
                    ite.show = false
                }
            })
            this.list = arr
        },
        limitSort(e) {
            this.inputNum = e.replace(/[^0-9]/g, '')
        },
        inputTopBlur(){
            var that = this
            if(that.inputNum===''){
                that.$errMsg('请输入排序(排序从大到小)')
                return
            }
            var params = {
                id:that.list[that.inputIndex].id,
                sort: that.inputNum,
            }
            that.$activityApi.activitySort(params).then(res => {
                if (res.code == 1000) {
                    that.$succMsg(res.message)
                    that.inputIndex = null
                    that.getList()
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 导出
        exportBtn() {
            var that = this
            var params = {
                role_id: that.roleValue,
                mobile: that.mobile,
                ids: that.ids.join(','),
            }
            that.$purviewApi.userExport(params).then(res => {
                return
            })
        },
    },
}
</script>

<style lang='scss' scoped>
.account_manage {
    width: 100%;
    .account_main {
        width: 100%;
        padding: 0 20px;
        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cond_left {
                display: flex;
                align-items: center;
                .el-button {
                    padding: 8px 10px;
                    margin-right: 20px;
                }
                .el-select {
                    margin-right: 20px;
                    width: 160px;
                }
                .el-input {
                    margin-right: 20px;
                    width: 160px;
                }
            }
            .cond_right {
                .el-button {
                    padding: 8px 10px;
                }
            }
        }
    }
}
.scope_txt{
    cursor: pointer;
}
.scope_tooltip{
    max-width: 800px;
    line-height: 20px;
}
.scope_sort{
    display: flex;
    align-items: center;
    justify-content: center;
    .input{
        width: 100px;
    }
    .icon{
        cursor: pointer;
        padding-left: 10px;
    }
}
</style>
